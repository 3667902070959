import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LegalPolicy = _resolveComponent("LegalPolicy")!

  return (_openBlock(), _createBlock(_component_LegalPolicy, {
    "country-code": _ctx.countryCode,
    branding: _ctx.branding,
    type: "terms-service"
  }, null, 8, ["country-code", "branding"]))
}